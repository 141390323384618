import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./auth";
const dataState = createPersistedState({
  paths: [
    "auth.authenticated",
    "auth.verified",
    "auth.role",
    "auth.permissions",
    "auth.user",
    "auth.token",
  ],
});

import propertyCategory from "./property-category";
import propertySubCategory from "./property-sub-category";
import consumer from "./consumer";
import district from "./district";
import propertyLocation from "./property-location";
import propertyPayment from "./property-payment";
import role from "./role";
import permission from "./permission";
export default createStore({
  modules: {
    auth,
    propertyCategory,
    propertySubCategory,
    consumer,
    district,
    propertyLocation,
    propertyPayment,
    role,
    permission,
  },
  plugins: [dataState],
  state: {
    snackBar: {},
    overlay: false,
  },
  getters: {},
  mutations: {
    SET_SNACKBAR: function (state, payload) {
      state.snackBar = payload;
    },
    SET_OVERLAY: function (state, payload) {
      state.overlay = payload;
    },
  },
  actions: {},
});
