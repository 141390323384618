<template>
  <v-card class="mx-auto">
    <v-navigation-drawer
      v-model="getDrawer"
      color="green-darken-3"
      class="mx-auto"
      width="300"
    >
      <v-sheet color="green-darken-3" class="px-10 py-5">
        <v-img
          :src="require('@/assets/logo.png')"
          position="center"
          aspect-ratio="16/7"
        />
      </v-sheet>
      <v-divider></v-divider>
      <v-list v-model:opened="open">
        <template
          v-for="{ icon, title, path, submenu, permission } in menu"
          :key="title"
        >
          <v-list-group
            :value="title"
            v-if="submenu.length > 0"
            v-show="hasPermission(permission)"
          >
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props" :prepend-icon="icon" :title="title" />
            </template>
            <v-list-item
              v-for="(
                { submenuIcon, submenuTitle, submenuPath, permission }, i
              ) in submenu"
              v-show="hasPermission(permission)"
              :key="i"
              :prepend-icon="submenuIcon"
              :title="submenuTitle"
              :value="submenuTitle"
              :to="submenuPath"
            />
          </v-list-group>
          <v-list-item
            v-show="hasPermission(permission)"
            v-else
            :prepend-icon="icon"
            :title="title"
            :to="path"
            link
          />
        </template>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <add-button
            :block="true"
            text="Logout"
            :loading="loading"
            @onButtonClicked="logoutHandler"
          />
        </div>
      </template>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import Auth from "@/mixins/Auth";
import AddButton from "@/components/butttons/AddButton";
import RolePermissions from "@/mixins/RolePermissions";

export default {
  name: "SidebarMenu",
  components: { AddButton },
  mixins: [Auth, RolePermissions],
  data() {
    return {
      loading: false,
      open: ["Home"],
      menu: [
        {
          icon: "mdi-home",
          title: "Home",
          path: "/",
          roleName: "search-property",
          permission: "home-menu",
          submenu: [],
        },
        /* {
          icon: "mdi-arrow-collapse-all",
          title: "Category",
          permission: "category-menu",
          submenu: [
            {
              submenuIcon: "mdi-format-line-spacing",
              submenuTitle: "Categories",
              submenuPath: "/property/categories",
              permission: "category-list",
            },
            {
              submenuIcon: "mdi-format-list-bulleted-type",
              submenuTitle: "Sub Categories",
              submenuPath: "/property/sub-categories",
              permission: "sub-category-list",
            },
          ],
        },*/
        /*{
          icon: "mdi-home-map-marker",
          title: "Property",
          permission: "property-menu",
          submenu: [
            {
              submenuIcon: "mdi-format-list-bulleted",
              submenuTitle: "Property List",
              submenuPath: "/property-locations",
              permission: "property-list",
            },
            {
              submenuIcon: "mdi-home-assistant",
              submenuTitle: "Create",
              submenuPath: "/property-location/create",
              permission: "property-create",
            },
          ],
        },*/
        {
          icon: "mdi-format-list-bulleted",
          title: "Properties",
          path: "/property-locations",
          permission: "property-list",
          submenu: [],
        },
        {
          icon: "mdi-account-multiple",
          title: "Consumers",
          path: "/consumers",
          permission: "consumer-menu",
          submenu: [],
        },
        {
          icon: "mdi-cash-multiple",
          title: "Payments",
          path: "/payments",
          permission: "payments-menu",
          submenu: [],
        },
        {
          icon: "mdi-account-multiple",
          title: "Users",
          path: "/auth/users",
          permission: "user-list",
          submenu: [],
        },
        /* {
          icon: "mdi-cog",
          title: "Role & Permission",
          permission: "role-permission-menu",
          submenu: [
            {
              submenuIcon: "mdi-account-multiple",
              submenuTitle: "Users",
              submenuPath: "/auth/users",
              permission: "user-list",
            },
            {
              submenuIcon: "mdi-format-list-bulleted-type",
              submenuTitle: "Role",
              submenuPath: "/auth/users/roles",
              permission: "role-list",
            },
            {
              submenuIcon: "mdi-folder-lock",
              submenuTitle: "Permissions",
              submenuPath: "/permissions",
              permission: "permission-list",
            },
          ],
        },*/
      ],
      isLogoutLoading: false,
    };
  },
  props: {
    drawer: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    getDrawer() {
      return this.drawer;
    },
  },
};
</script>

<style scoped></style>
