import districtHandler from "../../services/District";

export default {
  async load({ commit }, payload) {
    try {
      const {
        data: { data },
      } = await districtHandler.all(payload);
      commit("SET_DISTRICT", data);
    } catch (error) {
      commit("SET_DISTRICT", []);
    }
  },
};
