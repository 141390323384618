import AppSidebar from "@/layouts/AppSidebar";

const routes = [
  {
    path: "/",
    name: "dashboard",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/DashboardView"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/property/categories",
    name: "PropertyCategory",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/PropertyCategory"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/property/sub-categories",
    name: "PropertySubCategory",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/PropertySubCategory"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/consumers",
    name: "Consumers",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/ConsumersView"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/profile-settings",
    name: "ProfileSettings",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/ProfileSettings"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/property-locations",
    name: "PropertyLocations",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/PropertyLocation"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/property-location/create",
    name: "PropertyLocationCreate",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/CreatePropertyLocation"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/property-location/update/:id",
    name: "PropertyLocationUpdate",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/EditPropertyLocation"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/property-location/admin/property-details/:id",
    name: "PropertyLocationAdminPropertyDetails",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/AdminSinglePropertyDetails"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/property/details/:id",
    name: "PropertyDetails",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/SinglePropertyDetails"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/payments",
    name: "Payments",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/PaymentsView"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/auth/users",
    name: "AuthUsers",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/UsersView"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/auth/users/roles",
    name: "AuthUsersRoles",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/UserRolesView"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/permissions",
    name: "Permissions",
    components: {
      appBar: AppSidebar,
      default: () => import("../views/PermissionView"),
    },
    meta: { authOnly: true },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView"),
    meta: { guestOnly: true },
  },
];

export default routes;
