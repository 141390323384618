import permissionHandler from "@/services/Permission";

export default {
  async load({ commit }, payload) {
    try {
      const {
        data: { data },
      } = await permissionHandler.all(payload);
      // eslint-disable-next-line no-debugger
      debugger;
      commit("SET_ALL_PERMISSIONS", data);
    } catch (error) {
      commit("SET_ALL_PERMISSIONS", []);
    }
  },
};
