<template>
  <v-app id="inspire">
    <metainfo>
      <template v-slot:title="{ content }">
        {{ content }} - Vista Realty Search Engine
      </template>
    </metainfo>
    <snackbar-component />
    <router-view name="appBar" />
    <v-main>
      <v-row
        align="center"
        class="ma-4"
        justify="center"
        v-show="!checkVersion"
      >
        <p>
          New version {{ newVersion }} of your is available!!, please update
          &nbsp;
          <span>
            <v-btn
              color="warning"
              variant="flat"
              size="small"
              :loading="isLoading"
              @click="cacheHandler()"
            >
              Update
            </v-btn>
          </span>
        </p>
      </v-row>
      <router-view />
    </v-main>
    <v-footer class="bg-green-lighten-5" absolute inset app width="auto">
      <v-row justify="center" no-gutters>
        <v-col class="text-center mt-4" cols="12">
          @{{ new Date().getFullYear() }} —
          <strong class="devable-footer">
            all rights reserved
            <a href="https://vistarealty.com.bd/" target="_blank">
              Vista Realty
            </a>
            and made with
            <v-icon color="red">mdi-heart</v-icon>
            &nbsp;<a href="https://deveable.com/" target="_blank">Deveable</a>
          </strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import SnackbarComponent from "@/components/Snackbar";
import { useMeta } from "vue-meta";
import version from "@/json/version.json";

export default {
  setup() {
    useMeta({
      title: "Search Engin Admin Panel",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
    });
  },
  name: "App",
  components: { SnackbarComponent },
  data: () => ({
    newVersion: process.env.VUE_APP_VERSION,
    isLoading: false,
  }),
  computed: {
    checkVersion() {
      const jsonVersion = version.version;
      return this.newVersion === jsonVersion;
    },
  },
  created() {
    //this.clearCache();
  },
  methods: {
    cacheHandler() {
      this.isLoading = true;
      this.clearCache();
    },
    clearCache() {
      caches
        .keys()
        .then((keys) => Promise.all(keys.map((key) => caches.delete(key))))
        .finally(() => {
          this.isLoading = false;
          window.location.reload();
        });
    },
  },
};
</script>
<style>
.devable-footer a {
  text-transform: uppercase;
  text-decoration: none;
}
.devable-footer a:hover {
  color: #2e7d32;
}
</style>
