import Auth from "@/services/Auth";
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      loading: false,
      visible: false,
      checkbox: false,
      signUp: {
        email: "",
        password: "",
      },
      auth: {
        old_password: "",
        password: "",
        confirm_password: "",
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
      signIn: "auth/login",
      token: "auth/token",
    }),
    async login() {
      this.loading = true;
      await Auth.csrf();
      try {
        const {
          data: {
            data: { access_token },
          },
        } = await Auth.login(this.signUp);
        this.token(access_token);
        await this.signIn();
        // eslint-disable-next-line no-empty
      } catch (errors) {}
      this.loading = false;
    },
    async logoutHandler() {
      this.loading = true;
      try {
        await Auth.logout();
        this.setSnackbar(
          { message: "Successfully Logout", errors: [] },
          "info"
        );
        this.logout();
        // eslint-disable-next-line no-empty
      } catch (errors) {}
      this.loading = false;
    },
    setSnackbar(data, type) {
      this.$store.commit("SET_SNACKBAR", {
        data,
        type,
      });
    },
    async passwordHandler() {
      let { valid } = await this.$refs.formRef.validate();
      if (!valid) {
        return;
      }
      this.loading = true;
      try {
        const {
          data: { message },
        } = await Auth.changePassword(this.auth);
        this.setSnackbar({ message, errors: [] }, "info");
        this.$refs.formRef.resetValidation();
        //await this.logoutHandler();
        // eslint-disable-next-line no-empty
      } catch (errors) {}
      this.loading = false;
    },
  },
};
