import propertySubCategoryHandler from "../../services/PropertySubCategory";

export default {
  async load({ commit }, payload) {
    try {
      const {
        data: { data },
      } = await propertySubCategoryHandler.all(payload);
      commit("SET_SUB_CATEGORIES", data);
    } catch (error) {
      commit("SET_SUB_CATEGORIES", []);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async store({ commit, dispatch }, payload) {
    return propertySubCategoryHandler.store(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async get({ commit, dispatch }, payload) {
    return propertySubCategoryHandler.get(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async update({ commit, dispatch }, payload) {
    return propertySubCategoryHandler.update(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async remove({ commit, dispatch }, payload) {
    return propertySubCategoryHandler.remove(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async destroy({ commit, dispatch }, payload) {
    return propertySubCategoryHandler.destroy(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async restore({ commit, dispatch }, payload) {
    return propertySubCategoryHandler.restore(payload);
  },
};
