import Api from "@/services/Api";
const basePath = "/permission";
const all = (payload) => {
  const path = `${basePath}/all`;
  const endpoint = payload
    ? `${
        path +
        "?" +
        Object.keys(payload)
          .map((key) => key + "=" + payload[key])
          .join("&")
      }`
    : path;

  return Api.getRequest({
    endpoint,
  });
};

const search = (payload) => {
  const path = `${basePath}/search`;
  const endpoint = payload
    ? `${
        path +
        "?" +
        Object.keys(payload)
          .map((key) => key + "=" + payload[key])
          .join("&")
      }`
    : path;

  return Api.getRequest({
    endpoint,
  });
};

const revoke = (payload) => {
  return Api.postRequest({
    endpoint: `${basePath}/revoke`,
    data: payload,
  });
};
const assignRole = (payload) => {
  return Api.postRequest({
    endpoint: `${basePath}/assign-role`,
    data: payload,
  });
};

export default {
  all,
  revoke,
  search,
  assignRole,
};
