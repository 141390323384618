import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      role_name: "auth/role",
      permissions: "auth/permissions",
    }),
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    hasPermission(name) {
      return true;
      //return !!this.permissions.find((permission) => permission === name);
    },
  },
};
