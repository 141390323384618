import router from "@/router";
import auth from "@/services/Auth";
export default {
  namespaced: true,
  state: {
    authenticated: false,
    user: {},
    token: "",
    allUsers: [],
  },
  getters: {
    authenticated(state) {
      return state.authenticated;
    },
    user(state) {
      const { name, email, currency } = state.user;
      return { name, email, currency };
    },
    currency(state) {
      const { currency } = state.user;
      return currency;
    },
    token(state) {
      return state.token;
    },
    role(state) {
      const { roles } = state.user;
      return roles[0].name;
    },
    permissions(state) {
      const { roles } = state.user;
      return roles[0].permissions.map((item) => item.name);
    },
  },
  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },
    SET_USER(state, value) {
      state.user = value;
    },
    SET_TOKEN(state, value) {
      state.token = value;
    },
    SET_ALL_USERS(state, payloads) {
      state.allUsers = payloads;
    },
  },
  actions: {
    login({ commit }) {
      return auth
        .auth()
        .then(
          ({
            data: {
              data: { item },
            },
          }) => {
            commit("SET_USER", item);
            commit("SET_AUTHENTICATED", true);
            router.push({ name: "dashboard" });
          }
        )
        .catch(() => {
          commit("SET_TOKEN", null);
          commit("SET_USER", null);
          commit("SET_AUTHENTICATED", false);
        });
    },
    token({ commit }, payload) {
      commit("SET_TOKEN", payload);
    },
    logout({ commit }) {
      commit("SET_TOKEN", null);
      commit("SET_USER", {});
      commit("SET_AUTHENTICATED", false);
      router.push({ name: "login" });
    },
    async load({ commit }, payload) {
      try {
        const {
          data: { data },
        } = await auth.all(payload);
        commit("SET_ALL_USERS", data);
      } catch (error) {
        commit("SET_ALL_USERS", []);
      }
    },
    // eslint-disable-next-line no-unused-vars
    async store({ commit, dispatch }, payload) {
      return auth.store(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async update({ commit, dispatch }, payload) {
      return auth.update(payload);
    },
    // eslint-disable-next-line no-unused-vars
    async destroy({ commit, dispatch }, payload) {
      return auth.destroy(payload);
    },
  },
};
