import propertyLocationHandler from "@/services/PropertyLocation";

export default {
  async load({ commit }, payload) {
    try {
      const {
        data: { data },
      } = await propertyLocationHandler.all(payload);
      commit("SET_PROPERTY_LOCATIONS", data);
    } catch (error) {
      commit("SET_PROPERTY_LOCATIONS", []);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async search({ commit }, payload) {
    try {
      const {
        data: { data },
      } = await propertyLocationHandler.search(payload);
      return data;
    } catch (error) {
      return [];
    }
  },
  // eslint-disable-next-line no-unused-vars
  async searchByName({ commit }, payload) {
    try {
      const {
        data: { data },
      } = await propertyLocationHandler.searchByName(payload);
      return data.data;
    } catch (error) {
      return [];
    }
  },
  // eslint-disable-next-line no-unused-vars
  async store({ commit, dispatch }, payload) {
    return propertyLocationHandler.store(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async get({ commit, dispatch }, payload) {
    return propertyLocationHandler.get(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async update({ commit, dispatch }, payload) {
    return propertyLocationHandler.update(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async remove({ commit, dispatch }, payload) {
    return propertyLocationHandler.remove(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async destroy({ commit, dispatch }, payload) {
    return propertyLocationHandler.destroy(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async restore({ commit, dispatch }, payload) {
    return propertyLocationHandler.restore(payload);
  },
};
