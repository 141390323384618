import Api from "@/services/Api";
const basePath = "/property-payment";
const all = (payload) => {
  const path = `${basePath}/all`;
  const endpoint = payload
    ? `${
        path +
        "?" +
        Object.keys(payload)
          .map((key) => key + "=" + payload[key])
          .join("&")
      }`
    : path;

  return Api.getRequest({
    endpoint,
  });
};

const getByLocationId = (payload) => {
  const endpoint = `${basePath}/get-by-property-location-id/${payload.id}`;
  return Api.getRequest({
    endpoint,
  });
};

const store = (payload) => {
  return Api.postRequest({
    endpoint: `${basePath}/store`,
    data: payload,
  });
};

const remove = (payload) => {
  return Api.deleteRequest({
    endpoint: `${basePath}/remove/${payload.id}`,
  });
};

export default {
  all,
  getByLocationId,
  store,
  remove,
};
