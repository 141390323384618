import propertyCategoryHandler from "../../services/PropertyCategory";

export default {
  async load({ commit }, payload) {
    try {
      const {
        data: { data },
      } = await propertyCategoryHandler.all(payload);
      commit("SET_CATEGORIES", data);
    } catch (error) {
      commit("SET_CATEGORIES", []);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async store({ commit, dispatch }, payload) {
    return propertyCategoryHandler.store(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async get({ commit, dispatch }, payload) {
    return propertyCategoryHandler.get(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async update({ commit, dispatch }, payload) {
    return propertyCategoryHandler.update(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async remove({ commit, dispatch }, payload) {
    return propertyCategoryHandler.remove(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async destroy({ commit, dispatch }, payload) {
    return propertyCategoryHandler.destroy(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async restore({ commit, dispatch }, payload) {
    return propertyCategoryHandler.restore(payload);
  },
};
