<template>
  <v-btn
    :color="color"
    :block="block"
    :variant="outlined"
    :loading="loading"
    @click="onButtonClicked()"
    :prepend-icon="prependIcon"
  >
    {{ text }}
  </v-btn>
</template>

<script>
export default {
  name: "AddButton",
  props: {
    block: {
      type: Boolean,
      default: () => false,
    },
    outlined: {
      type: String,
      default: () => "outlined",
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    text: {
      type: String,
      default: () => "",
    },
    prependIcon: {
      type: String,
      default: () => "",
    },
    color: {
      type: String,
      default: () => "",
    },
  },
  methods: {
    onButtonClicked() {
      this.$emit("onButtonClicked");
    },
  },
};
</script>

<style scoped></style>
