import propertyPaymentHandler from "@/services/PropertyPayment";

export default {
  async load({ commit }, payload) {
    try {
      const {
        data: { data },
      } = await propertyPaymentHandler.all(payload);
      commit("SET_PAYMENTS", data);
    } catch (error) {
      commit("SET_PAYMENTS", []);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async store({ commit, dispatch }, payload) {
    return propertyPaymentHandler.store(payload);
  },

  // eslint-disable-next-line no-unused-vars
  async remove({ commit, dispatch }, payload) {
    return propertyPaymentHandler.remove(payload);
  },
};
