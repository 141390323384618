import roleHandler from "../../services/Role";

export default {
  async load({ commit }, payload) {
    try {
      const {
        data: { data },
      } = await roleHandler.all(payload);
      commit("SET_ROLES", data);
    } catch (error) {
      commit("SET_ROLES", []);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async store({ commit, dispatch }, payload) {
    return roleHandler.store(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async update({ commit, dispatch }, payload) {
    return roleHandler.update(payload);
  },
};
