<template>
  <v-snackbar
    v-model="display"
    :timeout="timeout"
    variant="elevated"
    :vertical="false"
    :color="type"
    transition="v-snackbar-transition"
    position="static"
  >
    <v-list
      lines="one"
      variant="plain"
      :bg-color="type"
      v-if="errors.length > 0"
    >
      <v-list-item
        v-for="(item, n) in errors"
        :key="n"
        :title="`${item.key}`"
        :subtitle="`${item.value}`"
      />
    </v-list>
    <div v-else>
      {{ text }}
    </div>
    <template v-slot:actions>
      <v-btn dark variant="text" @click="display = false"> Close </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  name: "SnackbarComponent",
  data() {
    return {
      text: "",
      errors: [],
      display: false,
      timeout: 5000,
      type: "success",
    };
  },
  created() {
    this.$store.watch(
      (state) => state.snackBar,
      () => {
        const {
          snackBar: { data, type },
        } = this.$store.state;
        this.errors = [];
        if (data.errors && Object.keys(data.errors).length > 0) {
          Object.keys(data.errors).forEach((key) => {
            this.errors.push({
              key,
              value: data.errors[key][0],
            });
          });
        } else {
          this.text = data.message;
        }
        this.display = true;
        this.type = type;
      }
    );
  },
};
</script>

<style lang="scss" scoped>
.v-list-item__content .v-list-item-title {
  text-transform: capitalize !important;
}
</style>
