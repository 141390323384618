import consumerHandler from "../../services/Consumer";

export default {
  async load({ commit }, payload) {
    try {
      const {
        data: { data },
      } = await consumerHandler.all(payload);
      commit("SET_CONSUMERS", data);
    } catch (error) {
      commit("SET_CONSUMERS", []);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async store({ commit, dispatch }, payload) {
    return consumerHandler.store(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async get({ commit, dispatch }, payload) {
    return consumerHandler.get(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async update({ commit, dispatch }, payload) {
    return consumerHandler.update(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async remove({ commit, dispatch }, payload) {
    return consumerHandler.remove(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async destroy({ commit, dispatch }, payload) {
    return consumerHandler.destroy(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async restore({ commit, dispatch }, payload) {
    return consumerHandler.restore(payload);
  },
};
