import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { vuetifyProTipTap } from "./plugins/tiptap";
import { loadFonts } from "./plugins/webfontloader";
import { createMetaManager } from "vue-meta";
import VueObserveVisibility from "vue3-observe-visibility";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import VueVideoPlayer from "@videojs-player/vue";
import "video.js/dist/video-js.css";
loadFonts();
const app = createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(vuetifyProTipTap)
  .use(VueViewer)
  .use(VueVideoPlayer)
  .use(createMetaManager())
  .use(VueObserveVisibility);

//await router.isReady();
app.mount("#app");
