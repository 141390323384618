<template>
  <sidebar-menu :drawer="drawer" />
  <v-app-bar color="green-darken-3">
    <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
    <v-app-bar-title>Vista Realty</v-app-bar-title>
    <v-spacer />
    <div class="pr-2 pr-sm-4 pr-md-6">
      <v-menu v-model="menu" :close-on-content-click="false" location="end">
        <template v-slot:activator="{ props }">
          <v-chip
            class="ma-2"
            variant="outlined"
            color="green-lighten-4"
            v-bind="props"
          >
            <v-icon icon="mdi-account-outline" start></v-icon>
            Profile
          </v-chip>
        </template>

        <v-card min-width="300">
          <v-list>
            <v-list-item
              :prepend-avatar="require('@/assets/avatar.jpg')"
              :subtitle="user.email"
              :title="user.name"
            >
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list>
            <v-list-item
              prepend-icon="mdi-cog"
              title="Settings"
              to="/profile-settings"
            />
            <v-list-item
              prepend-icon="mdi-login-variant"
              @click="logoutHandler"
              title="Logout"
            />
          </v-list>
        </v-card>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
import SidebarMenu from "@/components/menu/SidebarMenu";
import Auth from "@/mixins/Auth";
export default {
  name: "AppSidebar",
  components: { SidebarMenu },
  mixins: [Auth],
  data() {
    return {
      drawer: true,
      fav: true,
      menu: false,
      message: false,
      hints: true,
    };
  },
};
</script>

<style scoped></style>
