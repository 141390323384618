import Api from "./Api";

const login = async (data) => {
  return Api.postRequest({
    endpoint: "/auth/login",
    data: data,
  });
};

const logout = async () => {
  return Api.postRequest({
    endpoint: "/auth/logout",
  });
};

const auth = async () => {
  return Api.postRequest({
    endpoint: "/auth/me",
  });
};

const changePassword = async (payload) => {
  return Api.postRequest({
    endpoint: "/auth/change-password",
    method: "PUT",
    data: payload,
  });
};

const csrf = async () => {
  return Api.getRequestWithoutBaseApi({ endpoint: "/sanctum/csrf-cookie" });
};

const all = (payload) => {
  const path = `/auth/all`;
  const endpoint = payload
    ? `${
        path +
        "?" +
        Object.keys(payload)
          .map((key) => key + "=" + payload[key])
          .join("&")
      }`
    : path;

  return Api.getRequest({
    endpoint,
  });
};

const store = (payload) => {
  return Api.postRequest({
    endpoint: `/auth/store`,
    data: payload,
  });
};

const update = (payload) => {
  return Api.postRequest({
    endpoint: `/auth/update/${payload.id}`,
    method: "PUT",
    data: payload,
  });
};

const destroy = (payload) => {
  return Api.deleteRequest({
    endpoint: `/auth/destroy/${payload.id}`,
  });
};

export default {
  login,
  logout,
  auth,
  changePassword,
  csrf,
  all,
  store,
  update,
  destroy,
};
